import React, { useEffect, useState } from 'react';
import './ContentWall.css';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import PublishIcon from '@mui/icons-material/Publish';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Snackbar } from '@mui/material';
import { collection, doc, getDoc, getDocs, setDoc, Timestamp } from 'firebase/firestore';
import { currFirestore } from './App';

export const generateId = () => {
  // The document being created here is just to generate IDs.
  const newId = doc(collection(currFirestore, 'autoGeneratedId')).id;
  return newId;
};

const getLabel = (collection: string) => {
  switch (collection) {
    case 'links':
      return 'Link';
    case 'longForms':
    case 'listicles':
    case 'markdown':
      return 'Article';
    case 'videos':
      return 'Video';
    case 'quotes':
      return 'Quote';
    case 'quiz':
      return 'Quiz';
    case 'signUp':
      return 'Sign Up';
    case 'custom':
      return 'Custom';
  }
  return '';
};

// Save all of the small bits of data from content items directly to the contentRef. So only big chunks of data like content markdown for long forms are ignored. We want static generation to handle that stuff. Client rendering for the minor things.
interface ContentRef {
  docId: string;
  collection: string;
  path: string;
  tags: string[];
  image: string;
  title: string;
  link: string;
  description?: string;
  youtubeId?: string;
  quote?: string;
  quotes?: string[];
  author?: string;
  secondaryImage?: string;
  dateUpdated: Timestamp;
  dateCreated: Timestamp;
  goLiveDate: Timestamp | null;
}
interface ContentItem {
  id: string;
  contentRef: ContentRef | null;
  size: string;
  color: string;
  isHidden: boolean;
}
export interface ContentWallType {
  contentItems: ContentItem[];
  dateUpdated: Timestamp;
}

export default function ContentWall(props: { collectionName: string }) {
  enum eSizes {
    size1x1 = 'size1x1',
    size2x1 = 'size2x1',
  }
  enum eColors {
    blue = 'blue',
    purple = 'purple',
    green = 'green',
    red = 'red',
  }
  const [initialized, setInitialized] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [contentItems, setContentItems] = useState<ContentItem[]>([]);
  const [contentRefs, setContentRefs] = useState<ContentRef[]>([]);
  const contentItemsDoc = doc(currFirestore, 'staticDocs', `preview${props.collectionName}`);
  const contentItemsProductionDoc = doc(currFirestore, 'staticDocs', `live${props.collectionName}`);
  const defaultContentItem: ContentItem = {
    id: generateId(),
    size: eSizes.size1x1,
    color: eColors.blue,
    contentRef: null,
    isHidden: false,
  };

  useEffect(() => {
    const linksCollection = collection(currFirestore, 'links');
    const longFormsCollection = collection(currFirestore, 'longForms');
    const listiclesCollection = collection(currFirestore, 'listicles');
    const videosCollection = collection(currFirestore, 'videos');
    const quotesCollection = collection(currFirestore, 'quotes');
    const markdownCollection = collection(currFirestore, 'markdown');
    const allCollections = [linksCollection, longFormsCollection, listiclesCollection, videosCollection, quotesCollection, markdownCollection];

    if (!initialized) {
      setInitialized(true);
      const allPromises: Promise<any>[] = [];
      const allContentRefs: ContentRef[] = [];
      allCollections.forEach((collection) => {
        allPromises.push(
          getDocs(collection)
            .then((querySnap) => {
              const collectionArray: ContentRef[] = [];
              querySnap.forEach((docSnap) => {
                const contentRef = docSnap.data() as ContentRef;
                // We need to cherry pick certain properties because the data will also have exteneous values beyond what we need for ContentRefs.
                const collection = docSnap.ref.path.split('/')[0];
                collectionArray.push({
                  docId: docSnap.id,
                  collection,
                  path: docSnap.ref.path || '',
                  tags: contentRef.tags || [],
                  image: contentRef.image || '',
                  secondaryImage: contentRef.secondaryImage || '',
                  title: contentRef.title || '',
                  link: contentRef.link || '',
                  youtubeId: contentRef.youtubeId || '',
                  quote: contentRef.quote || '',
                  quotes: contentRef.quotes || [],
                  description: contentRef.description || '',
                  author: contentRef.author || '',
                  dateUpdated: contentRef.dateUpdated,
                  dateCreated: contentRef.dateCreated,
                  goLiveDate: contentRef.goLiveDate || null,
                });
              });
              collectionArray.sort((a, b) => {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              });
              allContentRefs.push(...collectionArray);
            })
            .catch((err) => {
              handleOpenSnackbar(`Something went wrong. ${err?.message}`);
            })
        );
      });
      Promise.all(allPromises)
        .then(() => {
          allContentRefs.push({
            docId: 'quiz',
            collection: 'quiz',
            path: '',
            tags: ['quiz'],
            image: '',
            title: 'Quiz',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'signUp',
            collection: 'signUp',
            path: '',
            tags: ['signUp'],
            image: '',
            title: 'Sign Up',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'typesOfAccountants',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'career'],
            image: '',
            title: 'Types of accountants',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'accountingFashion',
            collection: 'custom',
            path: '',
            tags: ['lifestyle'],
            image: '',
            title: 'Accountant fashion',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'coverLetterTips',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'tips', 'downloads', 'coverLetter'],
            image: '',
            title: 'Cover Letter Downloads',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'collegeResumeTips',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'tips', 'downloads', 'resume', 'college'],
            image: '',
            title: 'College Resume Downloads',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'highSchoolResumeTips',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'tips', 'downloads', 'resume', 'highSchool'],
            image: '',
            title: 'High School Resume Downloads',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'scholarshipEssayTips',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'tips', 'downloads', 'scholarship'],
            image: '',
            title: 'Scholarship Essay Downloads',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'recommendationLetterTips',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'tips', 'downloads'],
            image: '',
            title: 'Recommendation Letter Downloads',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'linkedInGuide',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'tips'],
            image: '',
            title: 'Our Guide to Setting Up Your Linkedin Page',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'interviewTips',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'tips'],
            image: '',
            title: 'The Best Interview Tips to Nail an Accounting Job',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'careerResources',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'career'],
            image: '',
            title: 'Career Resources and Downloads',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'youAlreadyDoIt',
            collection: 'custom',
            path: '',
            tags: ['career', 'lifestyle'],
            image: '',
            title: 'Accounting: You Already Do It',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });
          allContentRefs.push({
            docId: 'pathways',
            collection: 'custom',
            path: '',
            tags: ['careerPrep', 'career'],
            image: '',
            title: 'Pathways to Accounting',
            link: '',
            dateUpdated: Timestamp.fromMillis(0),
            dateCreated: Timestamp.fromMillis(0),
            goLiveDate: null,
          });

          setContentRefs(allContentRefs);
        })
        .then(() => {
          getDoc(contentItemsDoc)
            .then((docSnap) => {
              // Old content wall items
              const contentWall = docSnap.data() as ContentWallType;
              // update them in case there were changes:
              const updatedContentItems = contentWall?.contentItems.map((ci) => {
                return {
                  ...ci,
                  contentRef: allContentRefs.find((cr) => cr.docId === ci.contentRef?.docId) || null,
                };
              });
              setContentItems(updatedContentItems || []);
            })
            .catch((err) => {
              handleOpenSnackbar(`Something went wrong. ${err?.message}`);
            });
        });
    }
  }, [contentItemsDoc, initialized]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const saveContentItems = () => {
    const update: ContentWallType = {
      contentItems,
      dateUpdated: Timestamp.fromMillis(Date.now()),
    };
    setDoc(contentItemsDoc, update)
      .then(() => {
        handleOpenSnackbar('Changes will be ready to preview in about 3 minutes.');
      })
      .catch((err) => {
        handleOpenSnackbar(`Something went wrong. ${err?.message}`);
      });
  };

  const publishChanges = () => {
    handleClosePublishDialog();
    const update: ContentWallType = {
      contentItems,
      dateUpdated: Timestamp.fromMillis(Date.now()),
    };
    setDoc(contentItemsProductionDoc, update)
      .then(() => {
        handleOpenSnackbar('Changes will be live in about 3 minutes.');
      })
      .catch((err) => {
        handleOpenSnackbar(`Something went wrong and the changes are NOT live. ${err?.message}`);
      });
  };

  const addContentItem = () => {
    setContentItems([...contentItems, defaultContentItem]);
  };

  const handleOpenPublishDialog = () => {
    setOpenPublishDialog(true);
  };

  // const revertStaging = () => {
  //   getDoc(contentItemsProductionDoc)
  //     .then((prodDocSnap) => {
  //       const prodWall = prodDocSnap.data() as ContentWallType;
  //       console.log(prodWall);
  //       if (prodWall) {
  //         setDoc(contentItemsDoc, prodWall);
  //         const updatedContentItems = prodWall.contentItems;
  //         setContentItems(updatedContentItems || []);
  //       }
  //     })
  //     .catch((err) => {
  //       handleOpenSnackbar(`Something went wrong. ${err?.message}`);
  //     });
  // };

  const handleClosePublishDialog = () => {
    setOpenPublishDialog(false);
  };

  const handleOpenSnackbar = (msg: string) => {
    setOpenSnackbar(true);
    setSnackbarMessage(msg);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  interface ContentItemFormProps {
    contentItem: ContentItem;
  }
  const ContentItemForm = (props: ContentItemFormProps) => {
    const contentItem = props.contentItem;
    const index = contentItems.findIndex((item) => item.id === props.contentItem.id);

    const updateContentItem = (update: Partial<ContentItem>) => {
      setContentItems(
        contentItems.map((ci) => {
          if (ci.id === contentItem.id) {
            return {
              ...ci,
              ...update,
            };
          }
          return ci;
        })
      );
    };

    const updateContentItemIndex = (newIndex = 0) => {
      const oldIndex = index;
      const contentItemsCopy = [...contentItems];
      const element = contentItemsCopy[oldIndex];
      contentItemsCopy.splice(oldIndex, 1);
      contentItemsCopy.splice(newIndex, 0, element);
      setContentItems(contentItemsCopy);
    };

    const moveUp = () => {
      const contentItemsCopy = [...contentItems];
      const indexForitemToMove = contentItemsCopy.findIndex((item) => contentItem.id === item.id);
      const itemToMove = contentItemsCopy[indexForitemToMove];
      contentItemsCopy[indexForitemToMove] = contentItemsCopy[indexForitemToMove - 1];
      contentItemsCopy[indexForitemToMove - 1] = itemToMove;
      setContentItems(contentItemsCopy);
    };

    const moveDown = () => {
      const contentItemsCopy = [...contentItems];
      const indexForitemToMove = contentItemsCopy.findIndex((item) => contentItem.id === item.id);
      const itemToMove = contentItemsCopy[indexForitemToMove];
      contentItemsCopy[indexForitemToMove] = contentItemsCopy[indexForitemToMove + 1];
      contentItemsCopy[indexForitemToMove + 1] = itemToMove;
      setContentItems(contentItemsCopy);
    };

    const remove = () => {
      setContentItems(contentItems.filter((item) => contentItem.id !== item.id));
    };

    return (
      <form className="content-item-form" autoComplete="off">
        <div className="control-row">
          <IconButton key="remove" aria-label="Close" color="inherit" onClick={remove}>
            <CloseIcon />
          </IconButton>
          {index !== 0 && (
            <IconButton key="moveUp" aria-label="Move up" color="inherit" onClick={moveUp}>
              <ArrowUpwardIcon />
            </IconButton>
          )}
          {index !== contentItems.length - 1 && (
            <IconButton key="moveDown" aria-label="Move down" color="inherit" onClick={moveDown}>
              <ArrowDownwardIcon />
            </IconButton>
          )}
        </div>
        <FormControl className="form-control">
          <InputLabel htmlFor="contentRef">Content Item</InputLabel>
          <Select
            className="caq-select"
            variant="outlined"
            value={contentItem.contentRef?.docId}
            defaultValue=""
            onChange={(e) => {
              updateContentItem({ contentRef: contentRefs.find((ref) => ref.docId === e.target.value) || null });
            }}
            inputProps={{
              name: 'Content Item',
              id: 'contentRef',
            }}
          >
            {contentRefs.map((contentRef) => (
              <MenuItem value={contentRef.docId} key={contentRef.docId}>
                <small>{getLabel(contentRef.collection)}</small>
                <span>{contentRef.title}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="form-control">
          <InputLabel htmlFor="size">Size</InputLabel>
          <Select
            className="caq-select"
            variant="outlined"
            value={contentItem.size}
            onChange={(e) => {
              updateContentItem({
                size: e.target.value,
              });
            }}
            inputProps={{
              name: 'Size',
              id: 'size',
            }}
          >
            <MenuItem value={eSizes.size1x1}>1x1</MenuItem>
            <MenuItem value={eSizes.size2x1}>2x1</MenuItem>
          </Select>
        </FormControl>
        {/* <FormControl className="form-control">
          <InputLabel htmlFor="color">Color</InputLabel>
          <Select
            className="caq-select"
            variant="outlined"
            value={contentItem.color}
            onChange={(e) => {
              updateContentItem({
                color: e.target.value,
              });
            }}
            inputProps={{
              name: 'Color',
              id: 'color',
            }}
          >
            <MenuItem value={eColors.blue}>Blue</MenuItem>
            <MenuItem value={eColors.purple}>Purple</MenuItem>
            <MenuItem value={eColors.green}>Green</MenuItem>
            <MenuItem value={eColors.red}>Red</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl className="form-control">
          <InputLabel htmlFor="position">Position</InputLabel>
          <Select
            className="caq-select"
            variant="outlined"
            value={index}
            onChange={(e) => {
              updateContentItemIndex(Number(e.target.value));
            }}
            inputProps={{
              name: 'Position',
              id: 'position',
            }}
          >
            {contentItems.map((_, index) => (
              <MenuItem key={index} value={index}>
                {index}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="form-control">
          <InputLabel htmlFor="position">Hidden</InputLabel>
          <Select
            className="caq-select"
            variant="outlined"
            value={Boolean(contentItem.isHidden)}
            onChange={(e) => {
              updateContentItem({
                isHidden: e.target.value === 'true' ? true : false,
              });
            }}
            inputProps={{
              name: 'Hidden',
              id: 'isHidden',
            }}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
      </form>
    );
  };

  const itemsPerPage = 10;

  return (
    <div className="publish-page">
      <p>All items here will show on the Content Wall based on their "Position" value if "Hidden" is set to "No".</p>
      {contentItems ? (
        <div>
          <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={addContentItem}>
            <AddIcon />
            <span className="label">Add Content Item</span>
          </Button>
          <div className="content-wall-gallery">
            {contentItems.slice(itemsPerPage * (currentPage - 1), itemsPerPage * (currentPage - 1) + itemsPerPage).map((ci) => (
              <ContentItemForm key={ci.id} contentItem={ci} />
            ))}
          </div>
          <Pagination count={Math.floor(contentItems.length / itemsPerPage)} page={currentPage} onChange={handleChange} />          
          <p>
            Click "Save Changes" to save your current changes in the preview state. Click "Preview" to see what the wall would look like on the preview site before going live. And click "Publish" to
            take the saved changes in the preview state and push them to the live production site at <a href="https://joinaccountingplus.com">joinaccountingplus.com</a>.
          </p>
          <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={addContentItem}>
            <AddIcon />
            <span className="label">Add Content Item</span>
          </Button>
          <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={saveContentItems}>
            <SaveIcon />
            <span className="label">Save Changes</span>
          </Button>
          <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={() => window.open('https://caq-limitless-test.web.app/')}>
            <PreviewIcon />
            <span className="label">Preview</span>
          </Button>
          <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={handleOpenPublishDialog}>
            <PublishIcon />
            <span className="label">Publish</span>
          </Button>
        </div>
      ) : (
        'Loading...'
      )}
      {/* <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={revertStaging}>
        <PublishIcon />
        <span className="label">Revert Staging</span>
      </Button> */}
      <Dialog open={openPublishDialog} onClose={handleClosePublishDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Push changes live?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to push your current changes live?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePublishDialog}>No</Button>
          <Button onClick={publishChanges}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
        action={[
          <Button key="undo" color="secondary" size="small" onClick={handleCloseSnackbar}>
            CLOSE
          </Button>,
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}
